module.exports = (() => {
  if (process.env.NODE_ENV === 'production') {
    return process.env.REACT_APP_STAGING
      ? {
        apiHost: 'https://tcas65-staging-dot-tcas65.as.r.appspot.com',
      }
      : {
        // apiHost: 'https://tcas65.as.r.appspot.com',
        apiHost: 'https://admin-api-dot-tcas65.as.r.appspot.com',
      };
  }

  // Default config
  return {
    // apiHost: 'https://tcas65.as.r.appspot.com',
    // apiHost: 'https://tcas65-staging-dot-tcas65.as.r.appspot.com',
    // apiHost: 'https://admin-api-dot-tcas65.as.r.appspot.com',
    apiHost: 'http://localhost:8020',
  };
})();
