const menus = [
  {
    key: 'university',
    to: '/center',
    title: 'ข้อมูลศูนย์สอบ',
    roles: ['staff', 'center', 'center_staff'],
  },
  {
    key: 'school',
    to: '/site',
    title: 'ข้อมูลสนามสอบ',
    roles: ['staff', 'center', 'center_staff'],
  },
  {
    key: 'venue',
    to: '/center/management',
    title: 'จัดการสนามสอบ',
    roles: ['staff', 'center', 'center_staff'],
  },
  {
    key: 'applications',
    to: '/applications',
    title: 'ข้อมูลผู้สมัครสอบ',
    roles: ['center', 'center_staff'],
  },

  {
    key: 'stats',
    to: '/stats',
    title: 'สถิติสนามสอบ',
    roles: ['center', 'center_staff'],
  },

  {
    key: 'doc',
    to: '/tutorial',
    title: 'คู่มือการใช้งาน',
    roles: ['staff', 'center', 'center_staff'],
  },

  {
    key: 'contact',
    to: '/contact',
    title: 'ติดต่อผู้ดูแลระบบ',
  },

  // {
  //   key: 'logs',
  //   to: '/logs',

  //   title: 'Logs',
  //   permission: 'log',
  // },
];
module.exports = menus;
